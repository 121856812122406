import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../styles/Calendar.css";
import { Link } from "react-router-dom";
import { FaCalendarAlt, FaClock, FaArrowLeft } from "react-icons/fa";
import { db } from "../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import EditBookingModal from "./EditBookingModal"; // Import the modal component

const Schedule = () => {
  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentBooking, setCurrentBooking] = useState(null);
  const [reload, setReload] = useState(false); // Add this state
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [bookingToDelete, setBookingToDelete] = useState(null);

  const getCartName = (cart) => {
    return cart === "1" ? "Vagn 1&2" : "Vagn 3&4";
  };

  useEffect(() => {
    const fetchBookings = async () => {
      const querySnapshot = await getDocs(collection(db, "booking"));
      const fetchedBookings = querySnapshot.docs
        .map((doc) => {
          const data = doc.data();
          const startTime = data.starttime;
          const duration = data.duration;

          // Parse the date string
          const dateString = data.date;
          const [day, month, year, , time] = dateString.split(/[\s,]+/);
          const [hours, minutes, seconds] = time.split(":").map(Number);

          // Create a Date object
          const monthIndex = new Date(
            Date.parse(month + " 1, 2024")
          ).getMonth();
          const startDate = new Date(
            year,
            monthIndex,
            parseInt(day),
            hours,
            minutes,
            seconds
          );

          if (typeof startTime === "string" && typeof duration === "number") {
            const [startHours, startMinutes] = startTime.split(":").map(Number);
            const durationInMs = duration * 60 * 60 * 1000;
            startDate.setHours(startHours, startMinutes);
            const endDate = new Date(startDate.getTime() + durationInMs);
            const formattedTime = `${startDate
              .toTimeString()
              .slice(0, 5)}-${endDate.toTimeString().slice(0, 5)}`;
            return {
              dataDate: data.date,
              date: startDate,
              title: `${data.persons} - ${getCartName(data.cart)}`,
              time: formattedTime,
              id: doc.id,
              starttime: data.starttime,
              duration: data.duration,
              persons: data.persons,
              location: data.location,
              cart: data.cart,
              dateString: data.dateString,
            };
          } else {
            console.warn("Invalid booking data:", data);
            return null;
          }
        })
        .filter((booking) => booking !== null);
      setBookings(fetchedBookings);
    };

    fetchBookings();
  }, [reload]);

  const getTileContent = ({ date, view }) => {
    if (view === "month") {
      const bookingsForDay = bookings.filter(
        (booking) => booking.date.toDateString() === date.toDateString()
      );
      return bookingsForDay.length > 0 ? (
        <div className="bg-blue-grotto text-white p-1 rounded text-xs mt-1">
          {bookingsForDay.length}
        </div>
      ) : null;
    }
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const getDailyBookings = () => {
    return bookings
      .filter(
        (booking) => booking.date.toDateString() === selectedDate.toDateString()
      )
      .sort((a, b) => a.date - b.date); // Sort by date (time)
  };

  const handleEditBooking = (booking) => {
    setCurrentBooking(booking);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentBooking(null);
  };

  const handleSaveBooking = (updatedBooking) => {
    setReload((prev) => !prev); // Toggle the reload state to trigger useEffect
    handleCloseModal();
  };

  const handleDeleteBooking = async () => {
    if (bookingToDelete) {
      await deleteDoc(doc(db, "booking", bookingToDelete.id));
      setReload((prev) => !prev); // Reload bookings
      setIsConfirmDialogOpen(false);
      setBookingToDelete(null);
    }
  };

  const openConfirmDialog = (booking) => {
    setBookingToDelete(booking);
    setIsConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
    setBookingToDelete(null);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-500 to-green-400 flex justify-center items-center p-4 sm:p-6">
      <div className="bg-white rounded-lg shadow-xl p-6 sm:p-8 w-full max-w-md sm:max-w-4xl">
        <Link to="/" className="block mb-4">
          <button className="flex items-center text-blue-grotto hover:text-navy-blue transition duration-300">
            <FaArrowLeft className="mr-2" />
            Tillbaka Till Startsida
          </button>
        </Link>
        <h2 className="text-navy-blue text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center flex items-center justify-center">
          <FaCalendarAlt className="mr-2 sm:mr-3" />
          Schema
        </h2>
        <div className="custom-calendar-container">
          <Calendar
            onChange={setDate}
            value={date}
            tileContent={getTileContent}
            onClickDay={handleDateClick}
            className="custom-calendar w-full"
          />
        </div>
        {selectedDate && (
          <div className="mt-6 sm:mt-8">
            <h3 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">
              Bokningar för{" "}
              {selectedDate
                .toLocaleDateString("sv-SE", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </h3>
            <ul className="space-y-2">
              {getDailyBookings().map((booking, index) => (
                <li
                  key={index}
                  className="bg-gray-100 p-2 sm:p-3 rounded flex flex-col sm:flex-row items-start sm:items-center justify-between"
                >
                  <div className="flex items-center mb-2 sm:mb-0">
                    <FaClock className="mr-2 text-blue-grotto" />
                    <span>
                      {booking.time} <br /> {booking.title}
                    </span>
                  </div>
                  <div className="flex space-x-2">
                    {booking.date > new Date() && (
                      <>
                        <button
                          onClick={() => handleEditBooking(booking)}
                          className="bg-blue-grotto text-white px-2 py-1 rounded hover:bg-navy-blue transition duration-300"
                        >
                          Ändra bokning
                        </button>
                        <button
                          onClick={() => openConfirmDialog(booking)}
                          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-700 transition duration-300"
                        >
                          Avboka
                        </button>
                      </>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        {isModalOpen && currentBooking && (
          <EditBookingModal
            booking={currentBooking}
            onClose={handleCloseModal}
            onSave={handleSaveBooking}
          />
        )}
        {isConfirmDialogOpen && bookingToDelete && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded shadow-lg">
              <p>Är du säker på att du vill avboka?</p>
              <p>
                {bookingToDelete.time} - {bookingToDelete.title}
              </p>
              <div className="mt-4 flex justify-end">
                <button
                  onClick={closeConfirmDialog}
                  className="bg-gray-300 text-black px-2 py-1 rounded mr-2"
                >
                  Avbryt
                </button>
                <button
                  onClick={handleDeleteBooking}
                  className="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Bekräfta
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Schedule;
