import React from "react";
import { Link } from "react-router-dom";
import {
  FaCalendarAlt,
  FaPlus,
} from "react-icons/fa";

const MainPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-500 to-green-400 flex flex-col justify-center items-center p-6">
      <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full">
        <h1 className="text-navy-blue text-4xl font-bold mb-8 text-center">
          Boka vagn: Norrköping Södra
        </h1>
        <div className="space-y-6">
          <DashboardButton
            to="/schedule"
            icon={<FaCalendarAlt />}
            text="Visa Schema"
          />
          <DashboardButton
            to="/add-booking"
            icon={<FaPlus />}
            text="Lägg till bokning"
          />
        </div>
      </div>
    </div>
  );
};

const DashboardButton = ({ to, icon, text }) => (
  <Link to={to} className="block">
    <button className="bg-blue-grotto hover:bg-navy-blue text-white p-4 rounded-lg shadow-md w-full transition duration-300 ease-in-out flex items-center justify-center">
      <span className="mr-3">{icon}</span>
      <span className="font-semibold">{text}</span>
    </button>
  </Link>
);

export default MainPage;
