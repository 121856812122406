import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  doc,
  updateDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import Select from "react-select";
import {
  FaClock,
  FaHourglassHalf,
  FaUsers,
  FaClipboardList,
} from "react-icons/fa";

const EditBookingModal = ({ booking, onClose, onSave }) => {
  const [editedBooking, setEditedBooking] = useState({
    starttime: "",
    duration: "",
    persons: "",
    location: "",
    dateString: "",
  });

  const [isThursday, setIsThursday] = useState(false);
  const [isWeekend, setIsWeekend] = useState(false);
  const [unavailableTimeSlots, setUnavailableTimeSlots] = useState(new Set());

  useEffect(() => {
    if (booking) {
      const fetchUnavailableTimeSlots = async (selectedDate, selectedCart) => {
        if (!selectedDate || !selectedCart) {
          console.error(
            "Selected date or cart is undefined",
            selectedDate,
            selectedCart
          );
          return;
        }

        const bookingsQuery = query(
          collection(db, "booking"),
          where("dateString", "==", selectedDate),
          where("cart", "==", selectedCart)
        );

        const querySnapshot = await getDocs(bookingsQuery);
        const unavailableSlots = new Set();

        querySnapshot.forEach((doc) => {
          const bookingData = doc.data();
          if (doc.id !== booking.id) {
            // Exclude the current booking
            const startTime = bookingData.starttime;
            const endTime = calculateEndTime(startTime, bookingData.duration);
            unavailableSlots.add(`${startTime}-${endTime}`);
          }
        });

        setUnavailableTimeSlots(unavailableSlots);
      };

      setEditedBooking({
        starttime: booking.starttime,
        duration: booking.duration,
        persons: booking.persons,
        location: booking.location,
      });

      const bookingDate = new Date(booking.date);
      setIsThursday(bookingDate.getDay() === 4);
      setIsWeekend(bookingDate.getDay() === 0 || bookingDate.getDay() === 6);
      fetchUnavailableTimeSlots(booking.dateString, booking.cart);
    }
  }, [booking]);

  const isTimeSlotAvailable = (startTime, duration) => {
    const endTime = calculateEndTime(startTime, duration);
    for (const slot of unavailableTimeSlots) {
      const [slotStart, slotEnd] = slot.split("-");
      if (
        (startTime >= slotStart && startTime < slotEnd) ||
        (endTime > slotStart && endTime <= slotEnd) ||
        (startTime <= slotStart && endTime >= slotEnd)
      ) {
        return false;
      }
    }
    return true;
  };

  const generateThursdayTimeSlots = () => {
    const allSlots = [
      "08:00-10:00",
      "10:00-11:30",
      "11:30-12:30",
      "12:30-13:30",
      "13:30-14:30",
      "14:30-15:30",
    ];

    return allSlots
      .filter((slot) => !unavailableTimeSlots.has(slot))
      .map((timeSlot) => ({ label: timeSlot, value: timeSlot }));
  };

  const handleChange = (name, value) => {
    if (name === "starttime") {
      const newStartTime = value;
      const newDuration = editedBooking.duration;
      if (isTimeSlotAvailable(newStartTime, newDuration)) {
        setEditedBooking((prev) => ({ ...prev, starttime: newStartTime }));
      }
    } else if (name === "duration") {
      const newStartTime = editedBooking.starttime;
      const newDuration = value;
      if (isTimeSlotAvailable(newStartTime, newDuration)) {
        setEditedBooking((prev) => ({ ...prev, duration: newDuration }));
      }
    } else {
      setEditedBooking((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const bookingRef = doc(db, "booking", booking.id);

      await updateDoc(bookingRef, {
        starttime: editedBooking.starttime,
        duration: Number(editedBooking.duration),
        persons: editedBooking.persons,
        location: editedBooking.location,
      });

      onSave(editedBooking);
      onClose();
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };
  const generateTimeOptions = () => {
    const options = [];
    const startHour = isWeekend ? 10 : 8;
    const endHour = isWeekend ? 15 : 17;

    for (let i = startHour; i <= endHour; i++) {
      for (let j = 0; j < 60; j += 30) {
        const time = `${i.toString().padStart(2, "0")}:${j
          .toString()
          .padStart(2, "0")}`;
        if (isTimeSlotAvailable(time, editedBooking.duration)) {
          options.push({ label: time, value: time });
        }
      }
    }
    return options;
  };
  
  const generateDurationOptions = () => {
    const options = [];
    const [hours, minutes] = editedBooking.starttime.split(":").map(Number);
    const startDate = new Date(0, 0, 0, hours, minutes);
    const endLimit = new Date(0, 0, 0, isWeekend ? 16 : 18, 0);
    const maxDuration = (endLimit - startDate) / (1000 * 60 * 60);

    for (let i = 0.5; i <= Math.min(2, maxDuration); i += 0.5) {
      if (isTimeSlotAvailable(editedBooking.starttime, i)) {
        options.push({ label: `${i} timmar`, value: i });
      }
    }
    return options;
  };

  const handleTimeSlotChange = (option) => {
    const [start, end] = option.value.split("-");
    const duration =
      (new Date(`1970-01-01T${end}:00`) - new Date(`1970-01-01T${start}:00`)) /
      (1000 * 60 * 60);
    if (isTimeSlotAvailable(start, duration)) {
      setEditedBooking((prev) => ({
        ...prev,
        starttime: start,
        duration: duration,
      }));
    }
  };

  const calculateEndTime = (startTime, duration) => {
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const startDate = new Date(0, 0, 0, startHours, startMinutes);
    const endDate = new Date(startDate.getTime() + duration * 60 * 60 * 1000);
    return `${endDate.getHours().toString().padStart(2, "0")}:${endDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4">Ändra Bokning</h2>
        <form onSubmit={handleSubmit}>
          {isThursday ? (
            <div className="mb-3">
              <label className="text-sm font-medium flex items-center">
                <FaClock className="mr-2" />
                Bokningstid
              </label>
              <Select
                value={generateThursdayTimeSlots().find(
                  (option) =>
                    option.value ===
                    `${editedBooking.starttime}-${calculateEndTime(
                      editedBooking.starttime,
                      editedBooking.duration
                    )}`
                )}
                onChange={handleTimeSlotChange}
                options={generateThursdayTimeSlots()}
                placeholder="Välj tid"
              />
            </div>
          ) : (
            <>
              <div className="mb-3">
                <label className="text-sm font-medium flex items-center">
                  <FaClock className="mr-2" />
                  Starttid
                </label>
                <Select
                  value={generateTimeOptions().find(
                    (option) => option.value === editedBooking.starttime
                  )}
                  onChange={(option) => handleChange("starttime", option.value)}
                  options={generateTimeOptions()}
                  placeholder="Välj tid"
                />
              </div>
              <div className="mb-3">
                <label className="text-sm font-medium flex items-center">
                  <FaHourglassHalf className="mr-2" />
                  Varaktighet (timmar)
                </label>
                <Select
                  value={generateDurationOptions().find(
                    (option) => option.value === editedBooking.duration
                  )}
                  onChange={(option) => handleChange("duration", option.value)}
                  options={generateDurationOptions()}
                  placeholder="Välj varaktighet"
                />
              </div>
            </>
          )}
          <div className="mb-3">
            <label className="text-sm font-medium flex items-center">
              <FaUsers className="mr-2" />
              Personer
            </label>
            <input
              type="text"
              name="persons"
              value={editedBooking.persons}
              onChange={(e) => handleChange("persons", e.target.value)}
              className="w-full border rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-outline"
            />
          </div>
          <div className="mb-3">
            <label className="text-sm font-medium flex items-center">
              <FaClipboardList className="mr-2" />
              Plats / Övrig bokningsinformation
            </label>
            <input
              type="text"
              name="location"
              value={editedBooking.location}
              onChange={(e) => handleChange("location", e.target.value)}
              className="w-full border rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-outline"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 bg-gray-300 hover:bg-gray-400 transition duration-300 px-3 py-1 rounded"
            >
              Avbryt
            </button>
            <button
              type="submit"
              className="bg-blue-grotto text-white px-2 py-1 rounded hover:bg-navy-blue transition duration-300"
            >
              Spara
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditBookingModal;
