import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaLock, FaSignInAlt } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";

const TypingEffect = () => {
  const [currentText, setCurrentText] = useState('');
  const fullText = "Välkommen. Logga in för att fortsätta.";
  const typingSpeed = 100;

  useEffect(() => {
    if (currentText.length < fullText.length) {
      const timer = setTimeout(() => {
        setCurrentText(fullText.substring(0, currentText.length + 1));
      }, typingSpeed);

      return () => clearTimeout(timer);
    }
  }, [currentText, fullText]);

  return (
    <div className="text-center mb-6">
      <span className="text-navy-blue text-3xl font-bold mb-6 text-center">{currentText}</span>
    </div>
  );
};

const Login = () => {
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    login(password);
    navigate('/');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-500 to-green-400 flex justify-center items-center p-6">
      <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full">
        <TypingEffect />
        <form onSubmit={handleSubmit} className="space-y-6">
          <InputField
            type="password"
            placeholder="Lösenord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            icon={<FaLock />}
          />
          <button
            type="submit"
            className="bg-blue-grotto hover:bg-navy-blue text-white p-3 rounded-lg w-full transition duration-300 ease-in-out flex items-center justify-center"
          >
            <FaSignInAlt className="mr-2" />
            <span>Logga In</span>
          </button>
        </form>
      </div>
    </div>
  );
};

const InputField = ({ type, placeholder, value, onChange, icon }) => (
  <div className="relative">
    <span className="absolute left-3 top-3 text-gray-400">{icon}</span>
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="border border-gray-300 p-2 pl-10 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-grotto"
      autoComplete={type === "password" ? "current-password" : "off"}
    />
  </div>
);

export default Login;
